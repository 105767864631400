import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import './IssueColumnsDraggable.css';
import StickyHeader from '../../common/StickyHeader';
import IssueFilters from '../IssueFilters';
import Loader from '../../common/Loader';
import Column from './Column';
import { DragDropContext } from 'react-beautiful-dnd';
import { styled } from '../../../stitches.config';
import Tooltip from '../../common/Tooltip';
import ModalContent from '../../common/ModalContent';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { useAlert } from '../../../context/AlertContext';
import SprintIssuesFilters from '../SprintIssuesFilters';
import SprintItem from '../SprintItem';
import formatter from '../../../utils/formatter';

const SPRINT_MODAL_STATE = {
  LOADING: 'LOADING',
  PICK_ISSUES: 'PICK_ISSUE',
  CREATE_SPRINT: 'CREATE_SPRINT',
};

const INIT_STATE_SPRINT_FILTERS = {
  title: '',
  number: null,
  issueType: null,
};

const IssueColumnsDraggable = props => {
  const [loading, setLoading] = useState(true);
  const [issueIsMoving, setIssueIsMoving] = useState(true);
  const [columns, setColumns] = useState({});
  const [originalColumns, setOriginalColumns] = useState({});
  const [filters, setFilters] = useState({});
  const [openPRQAMasterModal, setOpenPRQAMasterModal] = useState(false);
  const [openCreateMilestoneModal, setOpenCreateMilestoneModal] =
    useState(false);
  const [newSprintTitle, setNewSprintTitle] = useState('');
  const [newSprintTitleModal, setNewSprintTitleModal] =
    useState('Crear nuevo sprint');
  const [newSprintDueDate, setNewSprintDueDate] = useState('');
  const [sprintOptions, setSprintOptions] = useState([]);
  const [alert, setAlert] = useState(null);
  const alertContext = useAlert();

  const [sprintModalState, setSprintModalState] = useState(
    SPRINT_MODAL_STATE.PICK_ISSUES,
  );
  const [newSprintIssues, setNewSprintIssues] = useState([]);
  const [sprintIssuesFilters, setSprintIssuesFilters] = useState(
    INIT_STATE_SPRINT_FILTERS,
  );

  useEffect(() => {
    if (alertContext.dashboardState.refreshRequired) {
      if (alertContext.dashboardState.project == props.match.params.project) {
        // alertContext.info('Actualizando tablero');
        getIssues();
        alertContext.setProjectRefreshed(props.match.params.project);
      }
    }
  }, [alertContext.dashboardState]);

  useEffect(() => {
    setTimeout(async () => {
      if (!issueIsMoving) {
        await apiHelper.refreshCache(props.match.params.project);
        await getIssueAfterAction();
      }
    }, [4000]);
  }, [issueIsMoving]);

  const [onboarding, setOnboarding] = useState({
    stepsEnabled: apiHelper.getOnboarding('onboardingProjectDetail'),
    initialStep: 0,
    steps: [
      {
        element: '.firstStep',
        intro:
          '<h4>¡Bienvenido a la pantalla de gestión de proyecto!</h4><br><p>Vamos a mostrarle la funcionalidad disponible</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.secondStep',
        intro:
          '<p>Cada proyecto de Venturing se organiza en tareas individuales. Cada tarea está identificada por un número único para el proyecto, y estará ubicada en una de seis columnas, donde cada columna indica un estado de progreso sobre dicha tarea.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.thirdStep',
        intro:
          '<p>La columna de <b>Backlog</b> reune tareas que deberán ser ejecutadas en mediano y largo plazo.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fourthStep',
        intro:
          '<p>La columna de <b>Pendientes</b> determina las tareas que deberán ser ejecutadas en el corto plazo. Esto incluye tareas planificadas y resolución de incidentes.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fifthStep',
        intro:
          '<p>La columna de <b>En Curso</b> indica en tiempo real las tareas en las que el equipo de Venturing está trabajando actualmente.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.sixthStep',
        intro:
          '<p>La columna de <b>QA</b> agrupa las tareas que están siendo revisadas por el equipo de calidad de Venturing, para asegurarse de que no contengan errores.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.seventhStep',
        intro:
          '<p>La columna de <b>Cliente</b> presenta las tareas que están listas para ser validadas por el cliente.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.eighthStep',
        intro:
          '<p>La columna de <b>Hechos</b> contiene las tareas que fueron validadas exitosamente por el cliente y han finalizado su ciclo. Si no reciben cambios durante cierto tiempo, serán archivadas y dejarán de ser visibles.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.ninethStep',
        intro:
          '<p>Cuando valide las tareas de la columna de <b>Cliente</b>, puede ocurrir que una tarea presente algún diferencia respecto a lo propuesto, en cuyo caso puede accederse al detalle de la misma para indicar el inconveniente, y luego puede moverla a <b>Pendientes</b> para que vuelva a ser revisada.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.tenthStep',
        intro:
          '<p>Si una tarea tiene la etiqueta <b>Atención</b>, indica que el equipo de Venturing tiene una necesidad o duda a resolver sobre la tarea, por lo que requiere atención del cliente para poder avanzar sobre la misma. En tal caso, puede acceder al detalle y responder con la información necesaria, y luego puede moverla a <b>Pendientes</b> para que reciba tratamiento.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.eleventhStep',
        intro:
          '<p>Si la tarjeta fue resuelta correctamente, puede moverla a <b>Hechos</b> para indicar que ha finalizado su desarrollo.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.twelvethStep',
        intro:
          '<p>Para crear nuevas tareas, puedes hacer click en el botón <b>Agregar</b>.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.thirtheenthStep',
        intro:
          '<p>También puedes fltrar las tareas del proyecto a través de los filtros en el menú superior.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fourteenthStep',
        intro:
          '<p>Para ver el detalle de una tarea en particular, puede hacer click sobre el título de la misma.</p>',
        tooltipClass: 'onboarding-steps',
      },
    ],
  });

  const refreshCache = async () => {
    setLoading(true);
    try {
      await apiHelper.refreshCache(props.match.params.project);
      await getIssues();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getIssues();
  }, []);

  useEffect(() => {
    console.log(filters);
    setColumns(filter(JSON.parse(JSON.stringify(originalColumns))));
    if (!loading && !Object.keys(originalColumns).length) getIssues();
  }, [filters]);

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const filter = columnsData => {
    if (filters.title)
      for (const column in columnsData) {
        const issuesTitles = columnsData[column].issues.filter(issue =>
          issue.title.toLowerCase().includes(filters.title.toLowerCase()),
        );
        const issuesNumbers = columnsData[column].issues.filter(issue =>
          issue.number.toString().includes(filters.title),
        );
        columnsData[column].issues = [...issuesTitles, ...issuesNumbers];
      }
    if (filters.issueType)
      for (const column in columnsData) {
        columnsData[column].issues = columnsData[column].issues.filter(issue =>
          issue.labels.some(label =>
            label.name.toLowerCase().includes(filters.issueType.toLowerCase()),
          ),
        );
      }
    if (filters.environment)
      for (const column in columnsData) {
        columnsData[column].issues = columnsData[column].issues.filter(issue =>
          issue.labels.some(label =>
            label.name
              .toLowerCase()
              .includes(filters.environment.toLowerCase()),
          ),
        );
      }
    if (filters.urgent)
      if (filters.urgent == true) {
        for (const column in columnsData) {
          columnsData[column].issues = columnsData[column].issues.filter(
            issue =>
              issue.labels.some(label =>
                label.name.toLowerCase().includes('urgent'),
              ),
          );
        }
      }
    if (filters.urgent == false) {
      for (const column in columnsData) {
        columnsData[column].issues = columnsData[column].issues.filter(
          issue =>
            !issue.labels.some(label =>
              label.name.toLowerCase().includes('urgent'),
            ),
        );
      }
    }
    if (filters.attention) {
      if (filters.attention == true) {
        for (const column in columnsData) {
          columnsData[column].issues = columnsData[column].issues.filter(
            issue =>
              issue.labels.some(label =>
                label.name.toLowerCase().includes('attention'),
              ),
          );
        }
      }
      if (filters.attention == false) {
        for (const column in columnsData) {
          columnsData[column].issues = columnsData[column].issues.filter(
            issue =>
              !issue.labels.some(label =>
                label.name.toLowerCase().includes('attention'),
              ),
          );
        }
      }
    }
    if (filters.useCase) {
      for (const column in columnsData) {
        console.log(columnsData[column].issues);
        columnsData[column].issues = columnsData[column].issues.filter(issue =>
          issue?.useCasesIds?.includes(filters.useCase),
        );
      }
    }
    if (filters.sprint)
      for (const column in columnsData) {
        columnsData[column].issues = columnsData[column].issues.filter(
          issue =>
            issue.milestone && issue.milestone.title == filters.sprint?.title,
        );
      }
    return columnsData;
  };

  const getIssueAfterAction = async () => {
    try {
      let columns = await apiHelper.getIssues(props.match.params.project);
      let formattedColumns = {};
      columns.data.map((column, index) => (formattedColumns[index] = column));
      let newSprintOptions = [];
      for (const column in columns.data) {
        columns.data[column].issues.forEach(issue => {
          if (
            issue.milestone &&
            !newSprintOptions.find(
              option => option.value == issue.milestone.title,
            )
          )
            newSprintOptions.push({
              value: issue.milestone.title,
              label: issue.milestone.title,
            });
        });
      }
      setSprintOptions(newSprintOptions);
      setOriginalColumns(formattedColumns);
      setColumns(filter(formattedColumns));
      console.log('columns');
      console.log(columns);
    } catch (e) {
      console.log(e);
      console.log(Object.keys(e));
      console.log(e?.response?.data?.error);
      setAlert(e?.response?.data?.error);
      console.log('Error loading issues');
    }
  };

  const getIssues = async () => {
    try {
      setLoading(true);
      const columns = await apiHelper.getIssues(props.match.params.project);
      let formattedColumns = {};
      columns.data.map((column, index) => (formattedColumns[index] = column));
      let newSprintOptions = [];
      for (const column in columns.data) {
        columns.data[column].issues.forEach(issue => {
          if (
            issue.milestone &&
            !newSprintOptions.find(
              option => option.value == issue.milestone.title,
            )
          )
            newSprintOptions.push({
              value: issue.milestone.title,
              label: issue.milestone.title,
            });
        });
      }
      setSprintOptions(newSprintOptions);
      setOriginalColumns(formattedColumns);
      setColumns(filter(formattedColumns));
      console.log(columns);
    } catch (e) {
      console.log(e);
      console.log(Object.keys(e));
      console.log(e?.response?.data?.error);
      setAlert(e?.response?.data?.error);
      console.log('Error loading issues');
    }
    setLoading(false);
  };

  const verifyRepositoryStructure = async () => {
    setLoading(true);
    try {
      await apiHelper.updateRepositoryStructure(props.match.params.project);
    } catch (e) {
      console.log(e);
      console.log('Error updating repository structure');
    }
    setLoading(false);
  };

  const createPullRequest = async () => {
    setLoading(true);
    let relatedIssues = [];
    if (
      originalColumns &&
      originalColumns['3'] &&
      originalColumns['3'].name == 'QA'
    ) {
      originalColumns['3'].issues.map(issue =>
        relatedIssues.push(issue.number),
      );
    }
    if (
      originalColumns &&
      originalColumns['4'] &&
      originalColumns['4'].name == 'Cliente'
    ) {
      originalColumns['4'].issues.map(issue =>
        relatedIssues.push(issue.number),
      );
    }
    try {
      await apiHelper.createPullRequest(
        props.match.params.project,
        relatedIssues,
        false,
      );
    } catch (e) {
      console.log(e);
      console.log('Error generating pull request');
    }
    setLoading(false);
    setOpenPRQAMasterModal(false);
  };

  const filteredIssueForSprint = () => {
    let issues = [];
    for (const columnIndex in columns) {
      issues = [...issues, ...columns[columnIndex].issues];
    }
    let filteredIssues = issues.filter(issue => issue.milestone == null);
    filteredIssues = filteredIssues.filter(issue => issue.closed_at == null);
    filteredIssues = filteredIssues.filter(
      issue => !newSprintIssues.some(picked => picked.number == issue.number),
    );
    if (sprintIssuesFilters.title) {
      console.log(sprintIssuesFilters);
      filteredIssues = filteredIssues.filter(issue =>
        issue.title?.includes(sprintIssuesFilters.title),
      );
    }
    if (sprintIssuesFilters.issueType) {
      filteredIssues = filteredIssues.filter(issue =>
        issue?.labels?.some(l => l.name == sprintIssuesFilters.issueType),
      );
    }

    if (sprintIssuesFilters.number) {
      filteredIssues = filteredIssues.filter(issue =>
        issue.number.toString().includes(sprintIssuesFilters.number.toString()),
      );
    }

    return filteredIssues;
  };

  const sprintModalContent = () => {
    switch (sprintModalState) {
      case SPRINT_MODAL_STATE.CREATE_SPRINT:
        return (
          <div>
            <>
              <div className="row mb-2">
                <div className="col-md-8">
                  <input
                    type="text"
                    name="sprintTitle"
                    className="form-control"
                    placeholder='Título del sprint (Ej: "Sprint 1")'
                    value={newSprintTitle}
                    onChange={e => setNewSprintTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="date"
                    name="sprintDueDate"
                    className="form-control"
                    min={formatter.formatDateSlash(new Date())}
                    placeholder="Fecha de entrega del sprint"
                    value={newSprintDueDate}
                    onChange={e => setNewSprintDueDate(e.target.value)}
                  />
                </div>
              </div>
              <span className="text-white">
                Se procederá a generar nuevo sprint con las siguientes tareas:
              </span>
              <ul
                className=""
                style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                {newSprintIssues.map((issue, is) => (
                  <li
                    className="d-flex flex-row justify-content-center"
                    key={`dsadsad${is}`}>
                    <div>{`#${issue.number} - ${issue.title}`}</div>
                  </li>
                ))}
              </ul>

              <div className="row">
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn butt btn-success w-100"
                    onClick={e =>
                      setSprintModalState(SPRINT_MODAL_STATE.PICK_ISSUES)
                    }>
                    Volver <i className="fas fa-backward ml-1" />
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn butt btn-success w-100"
                    disabled={!newSprintTitle || !newSprintDueDate}
                    onClick={e => createMilestone()}>
                    Crear hito <i className="fas fa-play ml-1" />
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn butt btn-success w-100"
                    onClick={e => {
                      setOpenCreateMilestoneModal(false);
                      setNewSprintIssues([]);
                      setSprintIssuesFilters(INIT_STATE_SPRINT_FILTERS);
                      setSprintModalState(SPRINT_MODAL_STATE.PICK_ISSUES);
                    }}>
                    Cancelar <i className="fas fa-stop ml-1" />
                  </button>
                </div>
              </div>
            </>
          </div>
        );
      case SPRINT_MODAL_STATE.LOADING:
        return <Loader></Loader>;
      case SPRINT_MODAL_STATE.PICK_ISSUES:
        const onPick = issue => {
          setNewSprintIssues([...newSprintIssues, issue]);
        };
        const onRemove = issue => {
          setNewSprintIssues(
            newSprintIssues.filter(old => old.number != issue.number),
          );
        };
        return (
          <>
            <span className="text-white">
              Se procederá a generar nuevo sprint con las siguientes tareas:
            </span>
            {newSprintIssues.length == 0 && (
              <div>Agrege las tareas para el nuevo Sprint</div>
            )}
            <ul
              className=""
              style={{ maxHeight: '170px', overflowY: 'scroll' }}>
              {newSprintIssues.map((issue, is) => (
                <li
                  className="d-flex flex-row justify-content-center"
                  key={`dsadsad${is}`}>
                  <div>{`#${issue.number} - ${issue.title}`}</div>
                  <button
                    onClick={() => onRemove(issue)}
                    className="btn btn-danger mb-2 ml-2 "
                    style={{
                      textAlign: 'center',
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}>
                    x
                  </button>
                </li>
              ))}
            </ul>

            <SprintIssuesFilters
              filters={sprintIssuesFilters}
              setFilters={setSprintIssuesFilters}></SprintIssuesFilters>
            <div
              className="d-flex flex-wrap justify-content-between mb-4"
              style={{ height: '300px', overflowY: 'scroll' }}>
              {filteredIssueForSprint().map((issue, i) => {
                return (
                  <div
                    onClick={() => onPick(issue)}
                    key={`KEYFORITEMSPRINT-${i}`}>
                    <SprintItem issue={issue}></SprintItem>
                  </div>
                );
              })}
            </div>

            <div className="row">
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  disabled={newSprintIssues.length == 0}
                  onClick={e =>
                    setSprintModalState(SPRINT_MODAL_STATE.CREATE_SPRINT)
                  }>
                  Siguiente <i className="fas fa-play ml-1" />
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn butt btn-success w-100"
                  onClick={e => {
                    setOpenCreateMilestoneModal(false);
                    setNewSprintIssues([]);
                    setSprintIssuesFilters(INIT_STATE_SPRINT_FILTERS);
                    setSprintModalState(SPRINT_MODAL_STATE.PICK_ISSUES);
                  }}>
                  Cancelar <i className="fas fa-stop ml-1" />
                </button>
              </div>
            </div>
          </>
        );
    }
  };

  const createMilestone = async () => {
    setSprintModalState(SPRINT_MODAL_STATE.LOADING);
    try {
      const issuesNumbers = newSprintIssues.map(issues => issues.number);
      console.log(issuesNumbers);
      await apiHelper.createMilestone(
        props.match.params.project,
        newSprintTitle,
        '',
        newSprintDueDate,
        issuesNumbers,
      );
      alertContext.success('Nuevo Sprint Creado');
      await getIssues();
      setSprintIssuesFilters(INIT_STATE_SPRINT_FILTERS);
      setNewSprintDueDate('');
      setNewSprintTitle('');
      setNewSprintIssues([]);
      setSprintModalState(SPRINT_MODAL_STATE.PICK_ISSUES);
      setOpenCreateMilestoneModal(false);
    } catch (e) {
      console.log(e);
      console.log('Error generating milestone');
      // setNewSprintTitleModal(
      //   'Error generando hito "' +
      //     newSprintTitle +
      //     '". Ya existe un hito con ese nombre.',
      // );
      alertContext.error('Se genero un error al crear el milestone');
    }
    window.location.reload();
    setLoading(false);
  };

  const onDragEnd = async ({ source, destination }: DropResult) => {
    try {
      // Make sure we have a valid destination
      if (destination === undefined || destination === null) return null;

      // Make sure we're actually moving the item
      if (
        source.droppableId === destination.droppableId &&
        destination.index === source.index
      )
        return null;

      // Set start and end variables
      const start = Object.entries(columns).find(
        existingColumn =>
          existingColumn[1].id.toString() === source.droppableId,
      )[1];
      const end = Object.entries(columns).find(
        existingColumn =>
          existingColumn[1].id.toString() === destination.droppableId,
      )[1];

      // If the user is a CLIENT and is trying to move a card into "En Curso" or "QA", prevent it
      if (
        apiHelper.isClient() &&
        (end.name == 'En Curso' || end.name == 'QA')
      ) {
        return null;
      }
      setIssueIsMoving(true);
      // If start is the same as end, we're in the same column
      if (start === end) {
        const movedIssue = start.issues[source.index];

        // Move the item within the list
        // Start by making a new list without the dragged item
        const newList = start.issues.filter(
          (_: any, idx: number) => idx !== source.index,
        );

        // Then insert the item at the right location
        newList.splice(destination.index, 0, start.issues[source.index]);

        // Then create a new copy of the column object
        const newCol = JSON.parse(JSON.stringify(start));
        newCol.issues = newList;

        // Update the state
        setColumns(state => ({
          ...state,
          [Object.entries(columns).findIndex(
            existingColumn =>
              existingColumn[1].id.toString() === source.droppableId,
          )]: newCol,
        }));

        // Call API to move issue in GitHub
        await apiHelper.moveIssue(movedIssue.cardId, {
          position:
            destination.index == 0
              ? 'top'
              : 'after:' + newCol.issues[destination.index - 1].cardId,
          columnId: start.id,
          projectName: props.match.params.project,
        });
      } else {
        // If start is different from end, we need to update multiple columns
        // Filter the start list like before

        const movedIssue = start.issues[source.index];

        const newStartList = start.issues.filter(
          (_: any, idx: number) => idx !== source.index,
        );

        // Create a new start column
        const newStartCol = JSON.parse(JSON.stringify(start));
        newStartCol.issues = newStartList;

        // Make a new end list array
        const newEndList = end.issues;

        // Insert the item into the end list
        newEndList.splice(destination.index, 0, start.issues[source.index]);

        // Create a new end column
        const newEndCol = JSON.parse(JSON.stringify(end));
        newEndCol.issues = newEndList;

        // Update the state
        setColumns(state => ({
          ...state,
          [Object.entries(columns).findIndex(
            existingColumn =>
              existingColumn[1].id.toString() === source.droppableId,
          )]: newStartCol,
          [Object.entries(columns).findIndex(
            existingColumn =>
              existingColumn[1].id.toString() === destination.droppableId,
          )]: newEndCol,
        }));

        // Call API to move issue in GitHub

        await apiHelper.moveIssue(movedIssue.cardId, {
          position:
            destination.index == 0
              ? 'top'
              : 'after:' + end.issues[destination.index - 1].cardId,
          columnId: newEndCol.id,
          projectName: props.match.params.project,
        });
      }
      setIssueIsMoving(false);

      return null;
    } catch (error) {
      console.log(error);
      setIssueIsMoving(false);
      return null;
    }
  };

  const StyledColumns = styled('div', {
    display: 'grid',
    gridTemplateColumns: '1fr '.repeat(Object.keys(columns).length),
    height: '80vh',
    gap: '8px',
  });

  let additionalOptions =
    apiHelper.isAdmin() ||
    apiHelper.isDEV() ||
    apiHelper.isQA() ||
    apiHelper.isPM() ||
    apiHelper.isDesigner() ? (
      <>
        <div className="col-lg-2">
          <a
            target="_blank"
            href={`https://dev.${props.match.params.project.toLowerCase()}.venturing.com.ar`}>
            <button
              data-tip
              data-for={'Tooltip-Project-Redirect'}
              type="button"
              className="btn butt btn-success pull-right w-100">
              Entorno de pruebas <i className="fas fa-external-link-alt" />
            </button>
            <Tooltip
              id={'Tooltip-Project-Redirect'}
              tooltipText={'Enlace hacia el entorno de pruebas del proyecto'}
            />
          </a>
        </div>
        <div className="col-lg-2">
          <button
            type="button"
            className="btn butt btn-success w-100"
            data-tip
            data-for={'Tooltip-Create-Sprint'}
            onClick={e => setOpenCreateMilestoneModal(true)}>
            Crear hito <i className="fas fa-calendar-check" />
          </button>
          <Tooltip
            id={'Tooltip-Create-Sprint'}
            tooltipText={
              'Genera un nuevo hito (sprint) en GitHub a partir de los issues en la columna "Pendientes".'
            }
          />
        </div>
        <div className="col-lg-2">
          <button
            type="button"
            className="btn butt btn-success w-100"
            data-tip
            data-for={'Tooltip-PR-QA-Master'}
            onClick={e => setOpenPRQAMasterModal(true)}>
            PR QA <i className="fas fa-arrow-right" /> Master{' '}
            <i className="fas fa-code-branch" />
          </button>
          <Tooltip
            id={'Tooltip-PR-QA-Master'}
            tooltipText={
              'Genera una nueva rama a partir de la rama QA, e inicia un pull request desde dicha rama a Master.'
            }
          />
        </div>
        <div className="col-lg-2">
          <Link
            to={`${process.env.PUBLIC_URL}/report/${props.match.params.project}`}>
            <button type="button" className="btn butt btn-success w-100">
              Reporte <i className="fas fa-chart-bar" />
            </button>
          </Link>
        </div>
        <div className="col-lg-2">
          <Link
            to={`${process.env.PUBLIC_URL}/project/${props.match.params.project}/issue/new`}>
            <button
              type="button"
              className="btn butt btn-success pull-right w-100 twelvethStep">
              Agregar <i className="fas fa-plus-square" />
            </button>
          </Link>
        </div>
        {!apiHelper.isClient() && (
          <div className="col-lg-2">
            <Link
              to={`${process.env.PUBLIC_URL}/project/${props.match.params.project}/useCases`}>
              <button
                type="button"
                className="btn butt btn-success pull-right w-100 twelvethStep">
                Casos de uso <i className="fas fa-clipboard-list" />
              </button>
            </Link>
          </div>
        )}
      </>
    ) : (
      <>
        <div className="col-lg-6" />
        <div className="col-lg-2">
          <a
            target="_blank"
            href={`https://dev.${props.match.params.project.toLowerCase()}.venturing.com.ar`}>
            <button
              data-tip
              data-for={'Tooltip-Project-Redirect'}
              type="button"
              className="btn butt btn-success pull-right w-100">
              Entorno de pruebas <i className="fas fa-external-link-alt" />
            </button>
            <Tooltip
              id={'Tooltip-Project-Redirect'}
              tooltipText={'Enlace hacia el entorno de pruebas del proyecto'}
            />
          </a>
        </div>
        <div className="col-lg-2">
          <Link
            to={`${process.env.PUBLIC_URL}/project/${props.match.params.project}/issue/new`}>
            <button
              type="button"
              className="btn butt btn-success pull-right w-100">
              Agregar <i className="fas fa-plus-square" />
            </button>
          </Link>
        </div>
      </>
    );

  const onExit = async () => {
    await apiHelper.updateOnboarding({
      onboarding: 'onboardingProjectDetail',
      state: false,
    });
    setOnboarding(prevState => {
      return { ...prevState, stepsEnabled: false };
    });
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <Steps
        enabled={onboarding.stepsEnabled && !loading}
        steps={onboarding.steps}
        initialStep={onboarding.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
        }}
        onExit={onExit.bind(this)}
      />

      <StickyHeader titulo={'Tareas'} icon={'fas fa-wrench icon-vtasks'} />

      <div className="container mw-100">
        {(apiHelper.isAdmin() || apiHelper.isQA()) && (
          <div className="row">
            <div className="col-lg-2 col-sm-6">
              <div className="text-right">
                <button
                  type="button"
                  className="btn butt btn-warning w-100"
                  onClick={refreshCache}
                  style={{ marginLeft: '2px', marginBottom: '15px' }}>
                  Recargar Cache <i className="fas fa-sync"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12 thirtheenthStep">
            <IssueFilters
              loadData={handleChangeFilters}
              sprintOptions={sprintOptions}
              additionalOptions={additionalOptions}
              project={props.match.params.project}
            />
          </div>
        </div>
      </div>
      {alert && (
        <div class="alert alert-warning" role="alert">
          {alert}
        </div>
      )}
      <div className="container mw-100">
        {loading ? (
          <Loader />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <StyledColumns>
              {Object.values(columns).map((column, index) => (
                <Column
                  key={column.id}
                  project={props.match.params.project}
                  column={column}
                  onboarding={onboarding.steps[index + 2].element.replace(
                    '.',
                    '',
                  )}
                />
              ))}
            </StyledColumns>
          </DragDropContext>
        )}
      </div>
      <ModalContent
        openModal={openPRQAMasterModal}
        title="Generar nuevo pull request desde la rama QA a Master"
        content={
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <span className="text-white">
                  Se procederá a generar un PR con las siguientes tareas:
                </span>
                <ul className="issue-list">
                  {originalColumns &&
                    originalColumns['3'] &&
                    originalColumns['3'].name == 'QA' &&
                    originalColumns['3'].issues.map(issue => (
                      <li>{issue.title}</li>
                    ))}
                  {originalColumns &&
                    originalColumns['4'] &&
                    originalColumns['4'].name == 'Cliente' &&
                    originalColumns['4'].issues.map(issue => (
                      <li>{issue.title}</li>
                    ))}
                </ul>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn butt btn-success w-100"
                      onClick={e => createPullRequest()}>
                      Crear PR <i className="fas fa-play ml-1" />
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn butt btn-success w-100"
                      onClick={e => setOpenPRQAMasterModal(false)}>
                      Cancelar <i className="fas fa-stop ml-1" />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        }
      />
      <ModalContent
        openModal={openCreateMilestoneModal}
        title={newSprintTitleModal}
        content={sprintModalContent()}
        // content={
        //   <div>
        //     {loading ? (
        //       <Loader />
        //     ) : (
        //       <>
        //         <div className="row mb-2">
        //           <div className="col-md-8">
        //             <input
        //               type="text"
        //               name="sprintTitle"
        //               className="form-control"
        //               placeholder='Título del sprint (Ej: "Sprint 1")'
        //               value={newSprintTitle}
        //               onChange={e => setNewSprintTitle(e.target.value)}
        //             />
        //           </div>
        //           <div className="col-md-4">
        //             <input
        //               type="date"
        //               name="sprintDueDate"
        //               className="form-control"
        //               placeholder="Fecha de entrega del sprint"
        //               value={newSprintDueDate}
        //               onChange={e => setNewSprintDueDate(e.target.value)}
        //             />
        //           </div>
        //         </div>
        //         <span className="text-white">
        //           Se procederá a generar nuevo sprint con las siguientes tareas:
        //         </span>
        //         <ul className="issue-list">
        //           {originalColumns &&
        //             originalColumns['1'] &&
        //             originalColumns['1'].name == 'Pendientes' &&
        //             originalColumns['1'].issues.map(issue => (
        //               <li>{issue.title}</li>
        //             ))}
        //         </ul>
        //         <div className="row">
        //           <div className="col-md-6">
        //             <button
        //               type="button"
        //               className="btn butt btn-success w-100"
        //               onClick={e => createMilestone()}>
        //               Crear hito <i className="fas fa-play ml-1" />
        //             </button>
        //           </div>
        //           <div className="col-md-6">
        //             <button
        //               type="button"
        //               className="btn butt btn-success w-100"
        //               onClick={e => setOpenCreateMilestoneModal(false)}>
        //               Cancelar <i className="fas fa-stop ml-1" />
        //             </button>
        //           </div>
        //         </div>
        //       </>
        //     )}
        //   </div>
        // }
      />
    </div>
  );
};

export default IssueColumnsDraggable;
